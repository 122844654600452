import * as Sentry from "@sentry/nextjs"

import type { NextPage } from "next"
import Head from "next/head"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/router"
import React, { useCallback, useState } from "react"

import { Button } from "@components/buttons"
import Input from "@components/input"
import Spinner from "@components/spinner"
import api from "@lib/api/routes"
import { parseError } from "@lib/errors"
import { validateEmail } from "@lib/helpers"
import { setOrganizationUser, setOrganizationUsers } from "@state/organizations"

const LoginPage: NextPage = () => {
    const router = useRouter()
    const [email, setEmail] = useState("")
    // const [emailError, setEmailError] = useState<string | null>(null)
    const [password, setPassword] = useState("")
    // const [passwordError, setPasswordError] = useState<string | null>(null)
    const [showPassword, setShowPassword] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [loggingIn, setLoggingIn] = useState(false)

    const [errors, setErrors] = useState<{
        email: string | null
        password: string | null
    }>({
        email: null,
        password: null,
    })

    // onChange
    const handleChange = (name: string, value: string) => {
        switch (name) {
            case "email":
                setEmail(value)
                break
            case "password":
                setPassword(value)
                break
            default:
                break
        }
    }

    // validations
    const validateField = (name: string, value: string) => {
        let error: string | null = null

        if (!value) {
            error = "Cannot be empty."
        } else if (name === "email" && !validateEmail(value)) {
            error = "Incorrect format."
        }

        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }))

        return error
    }

    const validateAllFields = () => {
        const isEmailValid = !validateField("email", email)
        const isPasswordValid = !validateField("password", password)

        return isEmailValid && isPasswordValid
    }

    const onLogin = useCallback(
        async (e: React.FormEvent) => {
            e.preventDefault()
            setLoggingIn(true)

            // Validate inputs
            if (!validateAllFields()) {
                setLoggingIn(false)
                return
            }

            try {
                // Login response will set auth token in cookie
                const data = await api.login({ email, password })

                setOrganizationUser(data.user.organization_user[0])
                setOrganizationUsers(data.user.organization_user)

                // Nav to home page
                router.push("/")
            } catch (err) {
                Sentry.captureException(err)
                console.error(err)
                setError(parseError(err))

                setLoggingIn(false)
            }
        },
        [
            email,
            password,
            validateAllFields,
            api.login,
            setOrganizationUser,
            setOrganizationUsers,
            router,
            setLoggingIn,
            setError,
            parseError,
        ],
    )

    return (
        <>
            <Head>
                <title>Log In - Nuve Platform</title>
            </Head>

            <div className="flex min-h-full justify-center bg-slate-100 lg:items-center">
                <div className="w-full border border-slate-200 bg-white p-6 lg:max-w-xl lg:rounded-md lg:p-10">
                    <h2 className="flex flex-col gap-4 text-3xl font-medium lg:flex-row">
                        <Image
                            src="/images/nuve-logo.svg"
                            width={75}
                            height={25}
                            alt="logo"
                        />
                        <span>Log in to Nuve Platform</span>
                    </h2>

                    <form className="mt-12 space-y-6" onSubmit={onLogin}>
                        <div className="flex flex-col gap-1">
                            <Input
                                type="email"
                                name="Email"
                                value={email}
                                placeholder="name@example.com"
                                disabled={loggingIn}
                                onChange={(value) => handleChange("email", value)}
                                autoFocus={true}
                                error={errors.email}
                            />
                        </div>
                        <div className="flex flex-col gap-1">
                            <Input
                                type={showPassword ? "text" : "password"}
                                name="Password"
                                value={password}
                                placeholder="••••••••••"
                                disabled={loggingIn}
                                onChange={(value) => handleChange("password", value)}
                                error={errors.password}
                            />
                        </div>
                        <div className="mb-4 flex items-center">
                            <input
                                id="showPassword"
                                type="checkbox"
                                className="rounded-sm border border-slate-500"
                                onClick={() => {
                                    setShowPassword(!showPassword)
                                }}
                            />
                            <label htmlFor="showPassword" className="ml-3 text-sm">
                                Show password
                            </label>
                        </div>

                        <div className="space-y-2">
                            {error && (
                                <p className="rounded bg-red-100 px-2 py-1 text-center text-sm text-red-600">
                                    {error}
                                </p>
                            )}
                            <Button
                                className="w-full text-xl"
                                onClick={onLogin}
                                disabled={loggingIn}
                                color="blue"
                            >
                                {loggingIn ? (
                                    <>
                                        <Spinner
                                            size="text-md"
                                            color="text-white"
                                            className="mt-1"
                                        />
                                        <span className="block">Logging in...</span>
                                    </>
                                ) : (
                                    <span className="block">Log in &rarr;</span>
                                )}
                            </Button>
                        </div>
                    </form>

                    <div className="mt-5 flex justify-center">
                        <Link
                            href="/auth/forgot"
                            className="text-sm text-blue-500 hover:text-blue-800"
                        >
                            Forgot your password?
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginPage
