import React from "react"
import { twMerge } from "tailwind-merge"

type ButtonProps = {
    type?: string
    children: React.ReactNode
    onClick: (args: any) => any
    disabled?: boolean
    color: string
    className?: string
    title?: string
    isWhite?: boolean
}

export const Button = ({
    type = "button",
    children,
    onClick,
    disabled = false,
    color,
    className,
    title,
    isWhite = false,
}: ButtonProps) => {
    let buttonClass = isWhite
        ? twMerge("white-btn", "px-2 py-1", className)
        : twMerge("color-btn", "px-3 py-2", className)
    if (color) {
        switch (color) {
            case "blue":
                buttonClass = twMerge(
                    buttonClass,
                    "btn-blue",
                    disabled && "btn-blue-disabled",
                )
                break
            case "red":
                buttonClass = twMerge(
                    buttonClass,
                    "btn-red",
                    disabled && "btn-red-disabled",
                )
                break
            case "slate":
                buttonClass = twMerge(
                    buttonClass,
                    "btn-slate",
                    disabled && "btn-slate-disabled",
                )
                break
            case "yellow":
                buttonClass = twMerge(
                    buttonClass,
                    "btn-yellow",
                    disabled && "btn-yellow-disabled",
                )
                break
            default:
                break
        }
    }

    if (type === "a") {
        if (disabled) {
            onClick = () => {
                return false
            }
        }
        return (
            <a className={buttonClass} onClick={onClick} title={title}>
                {children}
            </a>
        )
    } else {
        return (
            <button
                className={buttonClass}
                onClick={onClick}
                disabled={disabled}
                title={title}
            >
                {children}
            </button>
        )
    }
}
